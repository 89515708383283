/* eslint-disable no-undef */
angular.module("vgresiduos").controller("generalTopAlertsCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	"localStorageService",
	"$state",
	"dialogService",
	"engagementScoreService",
	"userAccessService",
	"clientService",
	"$sce",
	function (
		$scope,
		$rootScope,
		accountService,
		localStorageService,
		$state,
		dialogService,
		engagementScoreService,
		userAccessService,
		clientService,
		$sce
	) {
		"use strict";

		$scope.currentAlerts = [];

		let onChangeLoggedClient;
		const dateDiffParam = "[DATE_DIFF]";
		let currentClient = accountService.getCurrentClient();

		this.$onInit = function () {
			refreshAlerts();
			buildAlertPopups();
			buildNewFeaturesPopups();
			openDmrAlert();
			onChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
				currentClient = accountService.getCurrentClient();
				$scope.currentAlerts = [];
				refreshAlerts();
				buildAlertPopups();
				buildNewFeaturesPopups();
				openDmrAlert();
			});
		};

		function refreshAlerts() {
			if (!currentClient) {
				return;
			}

			const contractEndAlert = getContractEndAlert();
			if (contractEndAlert != null) {
				$scope.currentAlerts.push(contractEndAlert);
			}
			const contractBlockAlert = getContractBlockAlert();
			if (contractBlockAlert != null) {
				$scope.currentAlerts.push(contractBlockAlert);
			}
			const completeRegisterAlert = getCompleteRegisterAlert();
			if (completeRegisterAlert != null) {
				$scope.currentAlerts.push(completeRegisterAlert);
			}
			const bannerInfoAlert = getBannerInfoAlert();
			if (bannerInfoAlert != null) {
				$scope.currentAlerts.push(bannerInfoAlert);
			}
		}

		function buildAlertPopups() {
			if (!currentClient || !isCurrentClientPayer()) {
				return;
			}

			const alertPopups = [];

			openInfoAlertPopups(alertPopups);
		}

		function isCurrentClientPayer() {
			const contractTypePayerStatus = [
				Vgr.enumerations.organization.contractType.Payer,
				Vgr.enumerations.organization.contractType.Trial
			];

			return contractTypePayerStatus.includes(currentClient.organization.contractType);
		}

		this.$onDestroy = function () {
			// disable the listener
			onChangeLoggedClient();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function getContractEndAlert() {
			const endContractDate = Vgr.util.getDateFromString(currentClient.organization.endDate);
			const contractType = currentClient.organization.contractType;

			const contractEndAlert = {
				key: "contractEndAlertKey",
				showEasyAlert: false,
				showHardAlert: false,
				alertMessage: null
			};
			localStorageService.set(contractEndAlert.key, false);
			if (!endContractDate || !isCurrentClientPayer()) {
				return contractEndAlert;
			}

			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const dateDiff = Vgr.util.compareDays(endContractDate, today);

			if (dateDiff < 0) {
				contractEndAlert.showHardAlert = true;
				if (contractType == Vgr.enumerations.organization.contractType.Payer) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_END_DATE_ALERT_PAYER;
				} else if (contractType == Vgr.enumerations.organization.contractType.Trial) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_END_DATE_ALERT_TRIAL;
				}
			} else if (dateDiff < 30 && !localStorageService.get(contractEndAlert.key)) {
				contractEndAlert.showEasyAlert = true;
				if (contractType == Vgr.enumerations.organization.contractType.Payer) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_END_ALERT_PAYER.replace(
						dateDiffParam,
						dateDiff
					);
				} else if (contractType == Vgr.enumerations.organization.contractType.Trial) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_END_ALERT_TRIAL.replace(
						dateDiffParam,
						dateDiff
					);
				}
			}
			return contractEndAlert;
		}

		function getContractBlockAlert() {
			const blockDate = Vgr.util.getDateFromString(currentClient.organization.blockDate);

			const contractBlockAlert = {
				key: "contractBlockAlertKey",
				showEasyAlert: false,
				showHardAlert: false,
				alertMessage: null
			};
			localStorageService.set(contractBlockAlert.key, false);
			if (!blockDate || !isCurrentClientPayer()) {
				return contractBlockAlert;
			}

			const oneDay = 24 * 60 * 60 * 1000;
			const today = new Date();
			const dateDiff = Math.ceil((blockDate - today) / oneDay);

			if (dateDiff < 0) {
				contractBlockAlert.alertMessage = $rootScope.labels.CONTRACT_BLOCK_DATE_ALERT;
				contractBlockAlert.showHardAlert = true;
			} else if (dateDiff < 30 && !localStorageService.get(contractBlockAlert.key)) {
				const day = addZero(blockDate.getDate());
				const month = addZero(blockDate.getMonth() + 1);
				const year = blockDate.getFullYear();
				const block_day = dateStructure(day, month, year);
				contractBlockAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_BLOCK_ALERT.replace(
					"[BLOCK_DAY]",
					block_day
				);
				contractBlockAlert.showEasyAlert = true;
			}
			return contractBlockAlert;
		}

		function getCompleteRegisterAlert() {
			let completeRegisterAlert = null;
			if (currentClient.registerCompleted == undefined || !currentClient.registerCompleted) {
				completeRegisterAlert = {
					key: "completeRegisterAlertKey",
					showEasyAlert: true,
					alertMessage: $rootScope.labels.COMPLETE_REGISTER_MESSAGE,
					clickEvent: function () {
						$state.go("clientEdit", { id: currentClient.id });
					}
				};
			}
			return completeRegisterAlert;
		}

		const bannerInfoAlertKey = "disableAlertBanner_";
		function getBannerInfoAlert() {
			if ($rootScope.labels.BANNER_ALERT_MESSAGE) {
				const disableAlertBanner = localStorageService.get(bannerInfoAlertKey);
				if (!disableAlertBanner) {
					return {
						key: bannerInfoAlertKey,
						showEasyAlert: true,
						alertMessage: $rootScope.labels.BANNER_ALERT_MESSAGE,
						isHtml: false,
						clickEvent: function () {
							dialogService.confirm();
							const url = "";
							window.open(url, "_blank");
						}
					};
				}
			} else {
				localStorageService.set(bannerInfoAlertKey, false);
				return null;
			}
		}

		function addZero(variable) {
			if (variable < 10) {
				return "0" + variable;
			} else {
				return variable;
			}
		}

		function dateStructure(day, month, year) {
			return day + "/" + month + "/" + year;
		}

		function openInfoAlertPopups(alertPopups) {
			for (const popupAlert of alertPopups) {
				const disableInfoAlertPopup = localStorageService.get(popupAlert.alertPopupKey);
				if (!disableInfoAlertPopup) {
					dialogService
						.showDialogFromTemplateV2(
							"views/components/generalTopAlerts/popup/generalTopAlertsPopup_template.html",
							"GeneralTopAlertsPopupCtrl",
							".vgr-page",
							{ alertPopupModel: popupAlert },
							true
						)
						.then(
							function () {
								localStorageService.set(popupAlert.alertPopupKey, true);
							},
							function () {
								localStorageService.set(popupAlert.alertPopupKey, true);
							}
						);
				}
			}
		}

		$scope.closeAlert = function (storageKey) {
			const alert = $scope.currentAlerts.find((a) => a.key == storageKey);
			if (!alert.showHardAlert) {
				localStorageService.set(storageKey, true);
			}
			alert.showEasyAlert = false;
			alert.showInfoAlert = false;
			alert.showHardAlert = false;
		};

		$scope.executeAlertFunction = function (storageKey) {
			$scope.currentAlerts.forEach((a) => {
				if (a.key == storageKey && a.clickEvent) {
					a.clickEvent();
				}
			});
		};

		function openDmrAlert() {
			const dateMoment = moment();
			const hasDmrAccess = userAccessService.hasAccess(Vgr.systemPermissions.GenerateDmr);
			const dmrKeyPrefix = "dmrAlertPopup_";
			const dmrAlertPopupKey = `${dmrKeyPrefix}${dateMoment.quarter()}_${dateMoment.year()}`;
			const disableDmrAlertPopup = localStorageService.get(dmrAlertPopupKey);
			if (
				!disableDmrAlertPopup &&
				isPeriodToShowDmrAlert(dateMoment) &&
				hasDmrAccess &&
				!accountService.isMarketProfile()
			) {
				dialogService
					.showDialogFromTemplateV2(
						"views/components/generalTopAlerts/dmrAlertPopup/dmrAlertPopup_template.html",
						"dmrAlertPopupCtrl",
						".vgr-page",
						{ alertPopupModel: null },
						true
					)
					.then(
						function () {
							// do nothing
						},
						function () {
							// do nothing
						}
					);
			}
		}

		function isPeriodToShowDmrAlert(dateMoment) {
			const dayOfMonth = dateMoment.date();
			const currentMonth = dateMoment.month() + 1;
			const monthsAlert = [1, 4, 7, 10]; // meses p/ alerta
			return monthsAlert.includes(currentMonth) && dayOfMonth >= 5;
		}

		function buildNewFeaturesPopups() {
			if (!currentClient || !isCurrentClientPayer()) {
				return;
			}

			const reportData = buildCustomReportNewFeaturesPopup();
			if (reportData) {
				openNewFeaturesPopups(reportData);
			}
		}

		function buildCustomReportNewFeaturesPopup() {
			if (clientService.isSupplier()) {
				return;
			}

			if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.emissionDashboardBI)) {
				return buildByFeatureType(Vgr.enumerations.newFeaturesType.emissionDashboardBI);
			}
			if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.imaAlertPopup)) {
				return buildByFeatureType(Vgr.enumerations.newFeaturesType.imaAlertPopup);
			}
		}

		function buildByFeatureType(newFeatureType) {
			const featurePopupModel = getFeatureToShow(newFeatureType);
			if (!featurePopupModel) {
				return undefined;
			}
			return {
				key: featurePopupModel.key,
				imageLink: featurePopupModel.imageLink,
				knowMore: featurePopupModel.knowMore,
				buttons: featurePopupModel.buttons,
				isManualAccess: featurePopupModel.isManualAccess,
				isManualAccessKnowMoreBelow: featurePopupModel.isManualAccessKnowMoreBelow,
				hasContactOurSupport: featurePopupModel.hasContactOurSupport,
				hasGenericMessage: featurePopupModel.hasGenericMessage
			};
		}

		function getFeatureToShow(newFeatureType) {
			if (newFeatureType == Vgr.enumerations.newFeaturesType.marketingSurvey) {
				return getMarketingSurvey();
			}

			if (newFeatureType == Vgr.enumerations.newFeaturesType.customReportCarouselScreen) {
				return getDashboardBiCustomReport();
			}

			if (newFeatureType == Vgr.enumerations.newFeaturesType.emissionDashboardBI) {
				return getEmissionDashboardBINewFeature();
			}

			if (newFeatureType == Vgr.enumerations.newFeaturesType.imaAlertPopup) {
				return getImaAlertPopUp();
			}

			return undefined;
		}

		function openNewFeaturesPopups(reportData) {
			const disablePopup = localStorageService.get(reportData.key);
			if (disablePopup || reportData == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeatures/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: reportData },
					true
				)
				.then(
					function () {
						localStorageService.set(reportData.key, true);
					},
					function () {
						localStorageService.set(reportData.key, true);
					}
				);
		}

		$scope.trustedHtml = function (plainText) {
			return $sce.trustAsHtml(plainText);
		};

		function getDashboardBiCustomReport() {
			return {
				key: Vgr.enumerations.newFeaturesKey.customReportCarouselScreen,
				imageLink: "/img/generalTopAlerts/customReportCarouselScreen.svg",
				hasContactOurSupport: true,
				knowMore: function () {
					engagementScoreService.track(Vgr.trackings.bi.customReport.accessManualNewFeature);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.customReportCarouselScreen;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					}
				]
			};
		}

		function getMarketingSurvey() {
			return {
				key: Vgr.enumerations.newFeaturesKey.marketingSurvey,
				imageLink: "/img/generalTopAlerts/marketingSurvey.png",
				isManualAccess: false,
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.WANT_TO_PARTICIPATE,
						class: "md-primary md-raised",
						click: function () {
							dialogService.confirm();
							const url = Vgr.constants.marketingSurvey.baseUrl;
							window.open(url, "_blank");
						}
					}
				]
			};
		}

		function getEmissionDashboardBINewFeature() {
			const hasAccess =
				userAccessService.hasAccess(null, Vgr.constants.featureToggles.permission.esgModule) ||
				userAccessService.hasAccess(Vgr.systemPermissions.MasterViewClientData);
			if (!hasAccess) {
				return undefined;
			}

			return {
				key: Vgr.enumerations.newFeaturesKey.emissionDashboardBI,
				imageLink: "/img/generalTopAlerts/emissionDashboardBI.svg",
				isManualAccess: true,
				knowMore: function () {
					engagementScoreService.track(Vgr.trackings.bi.emission.accessManualNewFeature);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.emissionDashboardBI;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.ACCESS,
						class: "md-primary md-raised",
						click: function () {
							$state.go("esgEmbedPowerbi");
							dialogService.confirm();
						}
					}
				]
			};
		}
		function getImaAlertPopUp() {
			return {
				key: Vgr.enumerations.newFeaturesKey.imaAlertPopup,
				imageLink: "/img/generalTopAlerts/imaAlert.svg",
				hasGenericMessage: true,
				knowMore: function () {
					const url = Vgr.constants.imaAlertUrl.baseUrl;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.I_KNOW,
						class: "md-primary md-raised",
						click: function () {
							dialogService.confirm();
						}
					}
				]
			};
		}
	}
]);
